export default {
  data() {
    return {
      PERMISIONS: {
        list: false,
        edit: false,
      }
    }
  },
  created() {
    const baseRule = 'paap/:id/centralization'

    this.initPermision(`${baseRule}.get`,'list')
    this.initPermision(`${baseRule}.patch`,'edit')
  }
}