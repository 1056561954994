<template>
  <div style="padding-left: 1.75rem">
    <div class="col2_head">
      <router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
      {{ $route.name }}
      <div class="subHeadTitle">{{ this.$route.name }}</div>
      <div class="right" style="margin-top: 1.2rem">
        <div v-if="showPanel" @click="closePanel()" class="goBack">
          <img src="../assets/media/vectors/arrow_back.svg" alt="" />
        </div>
      </div>
    </div>
    <template v-if="PERMISIONS.list">
      <Table
        v-if="!$route.params.version"
        :key="tableReload"
        :reloadCount="tableReload"
        :Data="data"
        :fixedFilter="`paapId=${$route.params.id}`"
        @previousPage="setPage"
        @nextPage="setPage"
        @Btn2="editPaap"
        @changePage="changePage"
        @Btn7="viewRecord"
        @fullReload="reloadPage"
        @readFilteredData="filteredDataManager"
        @filterQuery="tableFilterManager"
      />

      <Table v-else :Data="historyData" @Btn7="viewRecord"></Table>
    </template>
    <template v-else>
      <h3 style="display: flex; flex-direction: column; align-items: center">
        <span style="font-size: 4rem">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        Acces interzis
      </h3>
    </template>
  </div>
</template>

<script>
import PermisionsMixin from "@/mixins/paapCentralizationPermMixin.js";
import updateRoleStatus from "../mixins/updateUserRoleStatus.js";
import {
  PAAP,
  GET_PAAP_CENTRALIZATION_PAGINATED,
  FINANCING,
  USERS_INSTITUTION,
  PAAP_CENTRALIZATION_EDIT,
  PROCEDURE_TYPE_PAGINATED,
  PROCEDURE_TYPE_WITH_REGISTER,
} from "../api.js";
import { mapMutations, mapGetters } from "vuex";
import EditPaapCentralization from "@/components/EditPaapCentralization";
import DialogModal from "@/components/DialogModal";

import Table from "@/components/Table";

export default {
  name: "PaapCentralizaction",
  $Title() {
    return `ConectX - ${this.$route.name}`;
  },
  components: {
    Table,
  },
  mixins: [PermisionsMixin, updateRoleStatus],
  data() {
    return {
      dataLoading: false,

      historyData: {
        ready: 1,
        head: [
          {
            title: "NR.",
            minimize: 2,
            sort: true,
            fixed: {
              left: true,
            },
          },
          {
            title: "Denumire",
          },
          {
            title: "CPV - Denumire",
          },
          {
            title: "Suma totală",
          },
          {
            title:
              "Procedura stabilită/instrumente specifice pentru derularea procesului de achiziție",
          },
          {
            title: "Rezultatul procedurii",
          },
          {
            title: "Sursa de finanțare",
          },
          {
            title: "Data(luna) estimată pentru inițierea proceduri",
          },
          {
            title:
              "Data(luna) estimată pentru atribuirea contractului de achiziție publică/semnarea acordului-cadru",
          },
          {
            title: "Modalitatea de derulare a procedurii de atribuire",
          },
          {
            title: "Persoana responsabilă cu aplicarea procedurii de atribuire",
          },
          {
            title:
              "Data introducerii procedurii în Programul anual al achizițiilor publice",
          },
          {
            spacer: true,
            minimize: 1,
            fixed: {
              right: true,
            },
          },
        ],
        body: [],
      },
      data: {
        ready: 1,
        options: {
          searchModule: "GET_PAAP_CENTRALIZATION_PAGINATED",
          partialDisableFixedCols: window.innerWidth < 500 ? [2, 1] : false,
          refresh: true,
        },
        head: [
          {
            title: "NR.",
            minimize: 2,
            sort: true,
            fixed: {
              left: true,
            },
          },
          {
            title: "Denumire",
            sort: true,
            queryKey: "name",
            bottomHead: {
              quikFiltre: true,
            },
          },
          {
            title: "CPV - Denumire",
            queryKey: "cpvCode",
            bottomHead: {
              quikFiltre: "string",
            },
          },

          {
            title:
              "Procedura stabilită/instrumente specifice pentru derularea procesului de achiziție",
            queryKey: "procedureType.name",
            bottomHead: {
              quikFiltre: "string",
            },
          },
          {
            title: "Rezultatul procedurii",
            queryKey: "procedureResult",
            bottomHead: {
              quikFiltre: "string",
            },
          },
          {
            title: "Valoare estimată(LEI fără TVA)",
            queryKey: "totalValueWithoutTva",
            bottomHead: {
              quikFiltre: "number",
            },
          },
          {
            title: "Sursa de finanțare",
            queryKey: "financingSource.sourceName",
            bottomHead: {
              quikFiltre: "string",
            },
          },
          {
            title: "Data (luna) estimată pentru inițierea proceduri",
            queryKey: "estimateInitProcedureDate",
            bottomHead: {
              quikFiltre: "date",
              type: "date",
              valueType: "date",
              range: true,
            },
          },
          {
            title:
              "Data (luna) estimată pentru atribuirea contractului de achiziție publică/semnarea acordului-cadru",
            queryKey: "estimateDateForSignContract",
            bottomHead: {
              quikFiltre: "date",
              type: "date",
              valueType: "date",
              range: true,
            },
          },
          {
            title: "Modalitatea de derulare a procedurii de atribuire",
            queryKey: "modalityToProcessProcedure",
            bottomHead: {
              quikFiltre: "string",
            },
          },
          {
            title: "Persoana responsabilă cu aplicarea procedurii de atribuire",
            queryKey: "responsablePersonRole",
            bottomHead: {
              quikFiltre: "string",
            },
          },
          {
            title:
              "Data introducerii procedurii în Programul anual al achizițiilor publice",
            queryKey: "dateForLastProcedureAdded",
            bottomHead: {
              quikFiltre: "date",
              type: "date",
              valueType: "date",
            },
          },
          {
            spacer: true,
            minimize: 1,
            fixed: {
              right: true,
            },
            bottomHead: {
              quikFiltre: "clear",
            },
          },
        ],
        body: [],
      },
      currentPage: this.getPaginatedParams(),
      totalItemsLength: 0,
      tableFilter: {
        query: "",
        baseQuery: `eq=paapId,${this.$route.params.id}`,
      },
      totalRecords: 0,
      tableReload: 0,
      financingSource: [],
    };
  },
  methods: {
    ...mapMutations({
      addWindow: "addWindow",
      setReferenceIdForPreview: "setReferenceIdForPreview",
      saveData: "setPaapCentralizationTableData",
    }),
    async reloadPage() {
      this.saveData([]);
      this.data.body.splice(0);
      this.tableReload = 0;
      this.totalItemsLength = 0;
      this.totalRecords = 0;
      (this.currentPage = this.getPaginatedParams()),
        (this.dataLoading = false);
      this.tableFilter.query = "";
      this.managePages();
    },
    setPage(from, to) {
      if ((!parseInt(from) && from !== 0) || (!parseInt(to) && to !== 0)) {
        return;
      }

      this.currentPage.from = from;
      this.currentPage.to = to;

      this.managePages();
    },

    changePage(visibleRows) {
      this.currentPage.from = 0;
      this.currentPage.to = visibleRows;

      this.managePages(true);
    },

    changePage(visibleRows) {
      this.currentPage.from = 0;
      this.currentPage.to = visibleRows;

      this.managePages(true);
    },

    async getPaapCentralizationData(showPerPage, page) {
      let prepare = [];

      let response = await GET_PAAP_CENTRALIZATION_PAGINATED(
        page,
        showPerPage,
        this.tableFilter.baseQuery.concat(this.tableFilter.query) || false
      );
      if (Number.isInteger(response?.data?.recordsQuantity)) {
        this.totalItemsLength = response.data.recordsQuantity;
        this.data.options.totalItems = response.data.recordsQuantity;
      }

      prepare = this.prepareData(response.data.result);

      return prepare;
    },
    async managePages(changePage) {
      if (this.dataLoading) {
        return;
      }
      this.dataLoading = true;

      let data = this.getPaapCentralizationTableData;

      const { from, to } = this.currentPage;
      const show = to - from;
      const currentPage = from / (to - from) + 1;
      const totalItems = this.totalItemsLength;

      if (to >= totalItems && data.length && changePage !== true) {
        return;
      }

      let uploadPages = [];
      let reloadRowsAfterChangeShow = false;

      if (currentPage > 0) {
        if (!data[from]) {
          uploadPages.push(currentPage);
        } else if (data[from] && !data[to - 1]) {
          this.data.body = [];
          data = [];
          reloadRowsAfterChangeShow = true;
          uploadPages.push(currentPage);
        }
      }
      if (Math.ceil(totalItems / show) - currentPage > 0) {
        if (!data[to]) {
          uploadPages.push(currentPage + 1);
        }
      }
      if (currentPage - 1 >= 1) {
        if (!data[from - show]) {
          uploadPages.push(currentPage - 1);
        }
      }

      if (
        this.tableReload === 1 &&
        uploadPages.length < 2 &&
        uploadPages.indexOf(currentPage + 1) == -1
      ) {
        uploadPages.push(currentPage + 1);
      }

      if (uploadPages.length) {
        let preparation = [];
        let prepare = [];
        let i = 0;
        for (const page of uploadPages) {
          const getPage = await this.getPaapCentralizationData(show, page);
          preparation.push(getPage);
          i++;
        }

        const lastRowIndex = data[data.length - 1]
          ? data[data.length - 1].index + 1
          : 0;

        preparation = preparation.flat(1);

        preparation.forEach((e, i) => {
          prepare.push(e.row);

          e.row.splice(0, 0, {
            val: lastRowIndex + (i + 1),
            border: "right",
          });
        });

        data.push(preparation);

        data = data.flat(1);

        data.sort((a, b) => b.id - a.id);

        data.forEach((e, i) => {
          e.NR = i;
          e.index = i;
        });

        this.saveData(data);

        this.data.body =
          this.data.body.concat(prepare).length === 0
            ? [false]
            : this.data.body.concat(prepare);

        if (reloadRowsAfterChangeShow || this.tableReload == 1)
          this.tableReload++;
      } else if (!this.tableFilter.query) {
        let prepare = [];

        data.forEach((e) => {
          prepare.push(e.row);
        });

        this.data.body = prepare;
      }

      this.dataLoading = false;
    },
    filteredDataManager(data, callback) {
      if (Number.isInteger(data?.data?.recordsQuantity)) {
        this.totalItemsLength = data.data.recordsQuantity;
      }
      if (Array.isArray(data?.data?.result)) {
        callback([
          this.prepareData(data.data.result, {
            minimize: true,
          }),
          data.data.recordsQuantity,
        ]);
        this.saveData(
          this.prepareData(data.data.result, {
            minimize: false,
          })
        );
        this.data.options.totalItems = parseInt(data.data.recordsQuantity);
        this.currentPage = {
          from: 0,
          to: localStorage.getItem("tableShowPerPage")
            ? parseInt(localStorage.getItem("tableShowPerPage"))
            : 15,
        };
        this.dataLoading = false;
      } else {
        callback([false]);
      }
    },
    tableFilterManager(newQuery) {
      if (this.isString(newQuery)) {
        this.tableFilter.query = newQuery;
      }
    },
    reload() {
      this.tableReload++;
    },

    prepareData(rows, o = {}) {
      const preparations = [];

      let i = 1;
      for (const row of rows) {
        let estimateInitProcedureDate = "-";

        if (row.estimateInitProcedureDate) {
          estimateInitProcedureDate = new Date(
            row.estimateInitProcedureDate
          ).toLocaleString("ro-ro", { month: "long", year: "numeric" });
        }

        let estimateDateForSignContract = "-";

        if (row.estimateDateForSignContract) {
          estimateDateForSignContract = new Date(
            row.estimateDateForSignContract
          ).toLocaleString("ro-ro", { month: "long", year: "numeric" });
        }

        let dateForLastProcedureAdded = "-";

        if (row.dateForLastProcedureAdded) {
          dateForLastProcedureAdded = new Date(
            row.dateForLastProcedureAdded
          ).toLocaleDateString("ro-RO", {
            day: "numeric",
            month: "long",
            year: "numeric",
          });
        }

        const preparation = [
          row.name || "-",
          row.cpvCode + " - " + row.cpvCodeName || "-",
          row.procedureType?.name || "-",
          row.procedureResult || "-",
          this.toPriceFormat(row.totalValueWithoutTva),
          row.financingSource?.sourceName || "-",
          {
            val: {
              field1: estimateInitProcedureDate,
              color:
                row.estimateInitProcedureDate &&
                (new Date(row.estimateInitProcedureDate) < new Date() ||
                  new Date(row.estimateInitProcedureDate) >
                    new Date(row.estimateDateForSignContract))
                  ? "rgba(255, 0, 0, 0.3)"
                  : "",
            },
          },
          estimateDateForSignContract,
          row.modalityToProcessProcedure || "-",
          this.readUserObject(row.responsablePersonRole?.user) +
            (row.responsablePersonRole?.department?.name
              ? ` — ${row.responsablePersonRole?.department?.name}`
              : "") +
            (row?.responsablePersonRole?.role
              ? ` — ${this.changeRoleView(row.responsablePersonRole?.role)}`
              : "") || "-",
          dateForLastProcedureAdded,
        ];

        const prepareBtn = [];
        prepareBtn.push(7);
        if (
          this.PERMISIONS.edit &&
          !this.isHistory &&
          ["draft", "rejected"].includes(row.paap.paapStatus)
        ) {
          prepareBtn.push(2);
        }

        if (prepareBtn.length) {
          preparation.push([prepareBtn]);
        }

        if (o.minimize) {
          preparation.splice(0, 0, {
            val: i,
            border: "right",
          });
          preparations.push(preparation);
        } else {
          if (o.numerotate) {
            preparation.splice(0, 0, {
              val: i,
              border: "right",
            });
          }

          const id = row.id ? row.id : "-";

          const returnData = { id, row: preparation, data: row };

          preparations.push(returnData);
        }
        i++;
      }

      if (o.minimize) {
        preparations.forEach((e, i) => (e._ID = i));
      } else if (o.minimize === false || o.numerotate) {
        preparations.forEach((e, i) => {
          e.NR = i;
          e.index = i;
        });
      }

      return preparations;
    },
    closePanel() {
      this.panelData = false;

      this.safeBackRoute();
    },
    openPanel(options) {
      if (["object", "function"].includes(typeof options)) {
        this.simulateLoad(
          () =>
            (this.panelData =
              typeof options == "function" ? options() : options)
        );
      }
    },
    openVersion() {
      const paapId = +this.$route.params.id;

      const version = +this.$route.params.version;

      const error = (msg) => {
        this.$toastr.e(msg || "Încărcarea versiunii a eșuat.");
        this.setLoad();
        this.reload();
      };
      PAAP.getVersions(paapId)
        .then((res) => {
          if (Array.isArray(res?.data?.result)) {
            const versionData = res?.data?.result.find(
              (el) => el.versionNumber == version
            );

            const prepared = this.prepareData(
              versionData.data.paapCentralization ??
                versionData.data.oldPaap.paapCentralization,
              { minimize: false }
            );

            const rows = [];

            prepared.forEach((e, i) => {
              e.row.splice(0, 0, {
                val: i + 1,
                border: "right",
              });

              rows.push(e.row);
            });

            this.saveData(prepared);

            this.historyData.body = rows;
          } else {
            error();
          }
        })
        .catch(error);
      return;
    },

    viewRecord(index) {
      const data = this.getPaapCentralizationTableData;
      const find = data.find((el) => el.NR == index);

      if (!find) return;

      this.syncUrlForPaap(find.data);
    },

    viewPaapVersions(data) {
      if (!this.isObject(data)) return;
      this.openHistoryModal(data);
    },
    syncUrlForPaap(selectedPaapData) {
      const version = this.$route.params.version;
      const paapId = this.$route.params.id;

      if (!version) {
        this.safePushRouter(`${this.$route.path}/${selectedPaapData.id}`);
      } else {
        this.safePushRouter(
          `/${this.$route.params.navigation}/paap/${paapId}/centralizare/${selectedPaapData.id}/history/${version}`
        );
      }
    },
    async editPaap(index) {
      const data = this.getPaapCentralizationTableData;

      const find = data.find((el) => el.NR == index);

      if (!find) return;

      find.data.responsablePersonRole = find.data.responsablePersonRole
        ? {
            ...find.data.responsablePersonRole,
            id: find.data.responsablePersonRole.id ?? this.makeid(6),
            _userId: find.data.responsablePersonRole.user.id,
            label:
              this.readUserObject(find.data.responsablePersonRole.user) +
              (find.data.responsablePersonRole.department?.name
                ? ` — ${find.data.responsablePersonRole.department?.name}`
                : "") +
              (find.data.responsablePersonRole.role
                ? ` — ${this.changeRoleView(
                    find.data.responsablePersonRole.role
                  )}`
                : ""),
          }
        : null;

      this.$modal.show(
        DialogModal,
        {
          target: "EditPaapCentralization",
          title: "Editează centralizarea",
          inputs: [
            {
              id: "editedPaapCentralization",
              type: "component",
              component: EditPaapCentralization,
              componentProps: {
                initial: find.data,
              },
              errReason: "Nu au fost completate toate secțiunile.",
            },
          ],
          acceptFinish(data) {
            const editedPaapCentralization = data.editedPaapCentralization;
            const keys = [
              "name",
              "procedureResult",
              "estimateInitProcedureDate",
              "estimateDateForSignContract",
              "modalityToProcessProcedure",
              "productType",
              "dateForLastProcedureAdded",
              "financingSource",
              "procedureType",
              "responsablePersonRole",
            ];
            let edited = false;

            for (const key of keys) {
              if (
                JSON.stringify(editedPaapCentralization[key]) !=
                JSON.stringify(find.data[key])
              ) {
                edited = true;
              }

              if (!editedPaapCentralization[key]) {
                this.$toastr.w("Nu au fost completate toate secțiunile.");
                return false;
              }
            }

            if (!edited) {
              this.$toastr.w("Nu au fost găsite modificări.");

              return false;
            }

            return true;
          },
          closeBtnText: "Anulează",
          button: {
            type: 2,
            value: "Salvează",
            handler: (data) => {
              const sendData = {
                ...data.editedPaapCentralization,
              };

              delete sendData.needReferate;
              delete sendData.paap;

              const error = (msg) => {
                this.$toastr.e(msg || "Ceva nu a mers bine.");
                this.reload();
                this.setLoad();
              };

              PAAP_CENTRALIZATION_EDIT(find.id, sendData)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta.HttpStatusCode)) {
                    this.$toastr.s("Centralizarea a fost modificată.");
                    this.reloadPage();
                    this.reload();
                    this.setLoad();
                  } else {
                    error();
                  }
                })
                .catch(error);
            },
          },
        },

        {
          name: "EditPaapCentralization",
          adaptive: true,
          width: "900",
          height: "800",
        }
      );
    },
  },
  computed: {
    ...mapGetters(["getPaapCentralizationTableData"]),
    showPanel() {
      return !!this.panelData;
    },
    allowTableSync() {
      return true;
    },

    isHistory() {
      return this.$route.path.includes("history");
    },
  },
  watch: {},
  created() {
    if (!this.PERMISIONS.list) {
      return;
    }

    if (this.$route.params.version && !this.$route.params.centralizationId) {
      this.openVersion();
    } else {
      this.managePages(true);
    }
  },
};
</script>
